import React, { useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"
import Layout from "./layout"
import { CrtPage } from "./question"
import { RoundButton } from "./button"
import GlitchBackground from "./glitch-background"
import { navigate } from "gatsby"
import theme from "./theme"
import logo from "./assets/mit-cav.png"
import dfiLogo from "./assets/DFI-logo-da.png"
import fcLogo from "./assets/Filmcentralen-logo.png"
import Opener from "./opener"
import { t, currentLanguage, Language } from "./i18n"

export default () => {
  const [done, setDone] = useState(false)
  useEffect(() => {
    setTimeout(() => setDone(true), 3500)
  }, [])

  if (done) {
    return <Opener />
  } else {
    return (
      <Layout menuHidden={true} alternateMenu={true}>
        <CrtPage
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ delay: 0.5, duration: 3, ease: "easeOut" }}
        >
          <Centered>
            <img src={logo} width="100%" />
          </Centered>
          {currentLanguage === Language.Danish ? (
            <CenteredBottom>
              <p>
                Dette website er en dansk oversættelse af det originale
                projekt-site:{" "}
                <a href="https://moondisaster.org">moondisaster.org</a>.
              </p>
              <p>
                Den danske version er lavet i samarbejde med Det Danske
                Filminstitut.
              </p>
              <img src={dfiLogo} />
              <img src={fcLogo} />
            </CenteredBottom>
          ) : null}
        </CrtPage>
      </Layout>
    )
  }
}

const Centered = styled.div({
  width: "500px",
  position: "absolute",
  top: "40vh",
  [theme.mq.mobile]: {
    width: "70vw",
  },
})

const CenteredBottom = styled.div({
  position: "absolute",
  textAlign: "center",
  lineHeight: "24px",
  bottom: "5vh",
  img: {
    margin: "1rem",
    height: "30px",
  },
  [theme.mq.mobile]: {
    padding: "0 1.5rem 0 1.5rem",
    fontSize: "14px",
    lineHeight: "18px",
    bottom: "2vh",
  },
})
